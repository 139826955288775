import React, { Fragment } from 'react';

import { AssessmentListing, KillChain } from '../../models/Assessment';
import { doRendezvousAssessment } from '../helpers';
import media from '../../assets/ModifiedLockyRendezvous.jpg';
import api from '../../api';

const assessment: AssessmentListing = {
  category: KillChain.C2,
  name: 'Modified Locky Rendezvous',
  media,
  longDesc: (
    <Fragment>
      <p>This test uses a slightly modified version of Locky for C2 rendezvous.</p>
      <ul>
        <li>This is a safe simulation. IronNet does not send data to these domains.</li>
      </ul>
    </Fragment>
  ),
  shortDesc:
    'This test simulates the domain generation algorithm (DGA) used by the Locky ransomware with minor modifications.',
  characteristics:
    'DNS requests are sent for domain names generated by a modified Locky algorithm. The final DNS request resolves to an IP address, followed by an HTTPS connection to the resolved domain.',
  warnings: {
    ironnet: [
      'Request DGA domains based on a slightly modified version of Locky and rendezvous with a C2 server.'
    ],
    attacker: [
      'Use similar requests to evade defenses which rely on blacklists of known C2 domains.',
      'Cause security teams to spend unnecessary resources ensuring malicious domain lists are up to date.',
      'Use similar algorithms to quickly recover from server takedowns and other actions meant to disrupt malicious operations.'
    ]
  },
  why:
    'Minor modifications to malware are low-cost and easy for attackers to perform and allow them to evade traditional signature-based security systems.',
  how:
    'IronDefense DGA analytics use an intricate set of domain features to classify DGA activity, so even malware communications with no known signatures can be identified.',
  doAssessment: async () => {
    const response = await api.get(`${process.env.REACT_APP_API_BASE_URL}/mlrdomains.json`);
    const { domains } = response.data;
    return doRendezvousAssessment(domains, `${process.env.REACT_APP_C2_02}/s`);
  }
};

export default assessment;
