import React, { FC, useState, useRef, ReactElement, CSSProperties } from 'react';

import styles from './Tooltip.module.css';
import toolTip from '../../assets/Icon/Tooltip.svg';

interface TooltipProps {
  tipText: string;
}

const Tooltip: FC<TooltipProps> = ({ tipText }): ReactElement => {
  const [showToolTip, setShowToolTip] = useState(false);
  const iconRef = useRef<HTMLImageElement>(null);
  const toolTipRef = useRef<HTMLParagraphElement>(null);

  function calculateStyles(): CSSProperties {
    const tooltipStyles: CSSProperties = {};
    if (showToolTip && toolTipRef.current && iconRef.current) {
      tooltipStyles.visibility = 'visible';
      // eslint-disable-next-line no-restricted-globals
      const windowWidth = window.innerWidth;
      const { offsetWidth: tooltipWidth } = toolTipRef.current;
      const { width: iconWidth, left: iconLeft } = iconRef.current.getBoundingClientRect();
      const iconMiddle = iconLeft + iconWidth / 2;
      const tooltipLeft = iconWidth / 2 - tooltipWidth / 2;
      const isOverflowingRight = iconMiddle + tooltipWidth / 2 > windowWidth;
      if (isOverflowingRight) {
        tooltipStyles.right = 0;
      } else {
        tooltipStyles.left = tooltipLeft;
      }
    }
    return { visibility: 'hidden', ...tooltipStyles };
  }

  return (
    <div className={styles.toolTip}>
      <img
        src={toolTip}
        ref={iconRef}
        alt="Tooltip"
        onMouseOver={() => setShowToolTip(true)}
        onFocus={() => setShowToolTip(true)}
        onBlur={() => setShowToolTip(false)}
        onMouseOut={() => setShowToolTip(false)}
      />
      <p className={styles.tipText} style={calculateStyles()} ref={toolTipRef}>
        {tipText}
      </p>
    </div>
  );
};

export default Tooltip;
