import React, { Fragment } from 'react';

import { AssessmentListing, KillChain } from '../../models/Assessment';
import { doDataTheftAssessment } from '../helpers';
import media from '../../assets/High-RateSensitiveDataTheft.jpg';

const assessment: AssessmentListing = {
  category: KillChain.ACTION,
  name: 'High-Rate Sensitive Data Theft',
  media,
  priority: Infinity, // this test is slow, start it first
  longDesc: (
    <Fragment>
      <p>
        This test transmits a large quantity of proprietary data, such as financial or medical
        records, extremely quickly.
      </p>
      <ul>
        <li>IronNet does not collect or send any proprietary data during this test.</li>
      </ul>
    </Fragment>
  ),
  shortDesc:
    'This test simulates the theft of a large quantity of sensitive data, such as financial or medical records.',
  characteristics:
    'A large volume of open-source code is sent from the browser to an adversary’s web server.',
  warnings: {
    ironnet: [
      'Transmit simulated sensitive business information to an external server at a high data transfer rate.'
    ],
    attacker: [
      'Quickly steal a large amount of sensitive information, such as financial data, medical data, trade secrets, sensitive business details, or other closely-guarded information.',
      'Use stolen data to further target internal systems, people, or other companies.'
    ]
  },
  why:
    'Attackers often use high data transfer rates to speed up exfiltration and obtain as much information as possible in a limited time at the cost of increased risk to their operations.',
  how:
    'IronDefense analytics can detect data exfiltration attempts that use high volume and/or high density communications. We focus on data exfiltration from internal computers to external sites,  by modelling clusters of like computers, and comparing individual computers to each other in their cluster.  This approach also identifies data exfiltration in encrypted traffic without the need to decrypt.',
  doAssessment: () => doDataTheftAssessment(`${process.env.REACT_APP_ACTON_02}/<count>/hr.php`)
};

export default assessment;
