import { observer } from 'mobx-react-lite';
import React, { FC, Fragment, useContext, useLayoutEffect } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import AssessmentResults from './components/AssessmentResults';
import CompletionModal from './components/CompletionModal';
import Header from './components/Header';
import Progress from './components/Progress';
import Start from './components/Start';
import HesDeadJim from './components/HesDeadJim';
import AssessmentsStore from './stores/AssessmentsStore';

const App: FC = observer(() => {
  const {
    testsStartedAt,
    testsDuration,
    percentComplete,
    runTests,
    checkPulse,
    isAlive
  } = useContext(AssessmentsStore);

  useLayoutEffect(() => {
    checkPulse().catch(() => {
      console.error('API is currently unavailable.');
    });
  }, [checkPulse]);

  return (
    <div>
      <Header />
      {isAlive && (
        <CSSTransition in={!!testsStartedAt} timeout={300} classNames="fade">
          {testsStartedAt ? (
            <Fragment>
              <TransitionGroup>
                <CSSTransition in={!!testsStartedAt} timeout={300} classNames="fade">
                  <Progress percent={percentComplete} duration={testsDuration} />
                </CSSTransition>
              </TransitionGroup>
              <CompletionModal />
              <AssessmentResults />
            </Fragment>
          ) : (
            <Start runTests={() => runTests()} />
          )}
        </CSSTransition>
      )}
      {!isAlive && <HesDeadJim isLoading={isAlive === null} />}
    </div>
  );
});

export default App;
