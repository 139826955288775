import React, { Fragment } from 'react';

import { AssessmentListing, KillChain } from '../../models/Assessment';
import { doRendezvousAssessment } from '../helpers';
import media from '../../assets/LockyRendezvous.jpg';
import api from '../../api';

const assessment: AssessmentListing = {
  category: KillChain.C2,
  name: 'Locky Rendezvous',
  media,
  longDesc: (
    <Fragment>
      <p>
        This test uses a domain generation algorithm (DGA) equivalent to that used by Locky
        ransomware to send apparently random DNS requests in an effort to contact a C2 server.
      </p>
      <ul>
        <li>This is a safe simulation. IronNet does not send data to these domains.</li>
      </ul>
    </Fragment>
  ),
  shortDesc:
    'This test simulates the domain generation algorithm (DGA) used by the Locky ransomware.',
  characteristics:
    'Many DNS requests are sent for domains that are known to be generated by the Locky algorithm. The final DNS request resolves to an IP address, followed by an HTTPS connection to the resolved domain.',
  warnings: {
    ironnet: [
      'Request domains that the Locky malware would use to rendezvous with its C2 server and make the connection.'
    ],
    attacker: [
      'Use similar requests to evade defenses which rely on blacklists of known C2 domains.',
      'Cause security teams to spend unnecessary resources ensuring malicious domain lists are up to date.',
      'Use similar algorithms to quickly recover from server takedowns and other actions meant to disrupt malicious operations.'
    ]
  },
  why:
    'Malware can use DGA to rendezvous communications with a C2 server while making the communications difficult to block.  The patterns of domains generated depend on some value known to both the malware and the C2 infrastructure, often the current date/time. Because DGA generates large, frequently changing sets of domains, it is difficult to consistently prevent malware from contacting C2 servers.',
  how:
    'IronDefense DGA analytics identify patterns of multiple, algorithmically- generated DNS queries, consistent with methods used by malware to rendezvous with command and control infrastructure. IronDefense detects word/semi-word DGA as well as traditional random DGA.',
  doAssessment: async () => {
    const response = await api.get(`${process.env.REACT_APP_API_BASE_URL}/lrdomains.json`);
    const { domains } = response.data;
    return doRendezvousAssessment(domains, `${process.env.REACT_APP_C2_01}/s`);
  }
};

export default assessment;
