import cc from 'classcat';
import { observer } from 'mobx-react-lite';
import React, { FC, KeyboardEvent, useState, Fragment } from 'react';
import { SlideDown } from 'react-slidedown';
import 'react-slidedown/lib/slidedown.css';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import { ReactComponent as MinusIcon } from '../../assets/Icon/Minus.svg';
import { ReactComponent as PlusIcon } from '../../assets/Icon/Plus.svg';
import Assessment, { Risk } from '../../models/Assessment';
import Heading from '../Heading';
import IconList from '../IconList';
import Loading from '../Loading';
import styles from './TestPanel.module.css';
import Icon from '../Icon';

interface TestPanelProps {
  assessment: Assessment;
}

const TestPanel: FC<TestPanelProps> = observer(({ assessment }) => {
  const [isExpanded, setExpanded] = useState<boolean>(false);

  function handleBtnKeyDown(event: KeyboardEvent): void {
    if (event.key === ' ' || event.key === 'Enter' || event.key === 'Spacebar') {
      // "Spacebar" for IE11 support
      event.preventDefault();
      setExpanded(!isExpanded);
    }
  }

  return (
    <div className={cc([styles.default, { [styles.expanded]: isExpanded }])}>
      <div
        className={styles.heading}
        onClick={() => setExpanded(!isExpanded)}
        onKeyPress={handleBtnKeyDown}
        tabIndex={0}
        role="button"
        aria-pressed={isExpanded}
      >
        <div className={styles['assessment-name']}>
          <span className={styles.icon}>
            {isExpanded ? (
              <MinusIcon />
            ) : (
              <PlusIcon style={{ color: 'var(--color--gray-light)' }} />
            )}
          </span>
          <Heading tag="h4">
            {assessment.category}: {assessment.name}
          </Heading>
        </div>
        <div className={styles.desc}>
          <p>{assessment.shortDesc}</p>
        </div>
        <TransitionGroup component={null}>
          {!assessment.isRunning ? (
            <Fragment>
              <CSSTransition in={!assessment.isRunning} timeout={300} classNames="fade">
                <div className={styles.center}>
                  <Icon
                    mode="medium"
                    type={assessment.risk === Risk.High ? 'high' : 'low'}
                    title={assessment.risk === Risk.High ? 'Successful Test' : 'Unsuccessful Test'}
                    bubble
                    wrap="wrap"
                  />
                </div>
              </CSSTransition>
            </Fragment>
          ) : (
            <CSSTransition in={!assessment.isRunning} timeout={300} classNames="fade">
              <div className={styles.center}>
                <Loading />
              </div>
            </CSSTransition>
          )}
        </TransitionGroup>
      </div>
      <SlideDown className="slider" closed={!isExpanded}>
        <div className={cc([styles.content, !assessment.media && styles.fullContent])}>
          {assessment.media && (
            <div className={styles.contentMedia}>
              <img src={assessment.media} alt={assessment.name} />
            </div>
          )}
          <div className={styles.contentBody}>
            <Heading tag="h5" className={styles.sectionHeading}>
              Test Description
            </Heading>
            <div>{assessment.longDesc}</div>
            <Heading tag="h5" className={styles.sectionHeading}>
              Characteristics of a Real Attack
            </Heading>
            <div>
              <p>{assessment.characteristics}</p>
            </div>
            <SlideDown className="slider" closed={assessment.isRunning}>
              <div className={styles.warnings}>
                <div>
                  <Heading tag="h5" className={styles.sectionHeading}>
                    {assessment.risk === Risk.High ? 'We were able to:' : 'We were unable to:'}
                  </Heading>
                  {assessment.warnings.ironnet && assessment.warnings.ironnet.length > 0 && (
                    <IconList
                      icon={assessment.risk === Risk.High ? 'high' : 'low'}
                      items={assessment.warnings.ironnet}
                      mode="small"
                    />
                  )}
                </div>
                <div>
                  <Heading tag="h5" className={styles.sectionHeading}>
                    Warnings (what an attacker can do):
                  </Heading>
                  {assessment.warnings.attacker && assessment.warnings.attacker.length > 0 && (
                    <IconList icon="warning" items={assessment.warnings.attacker} mode="small" />
                  )}
                </div>
              </div>
              <Heading tag="h5" className={styles.ioc}>
                IOC
              </Heading>
              <p className={styles.ioc}>{assessment.ioc}</p>
            </SlideDown>
          </div>
        </div>
      </SlideDown>
    </div>
  );
});

export default TestPanel;
