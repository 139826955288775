import { observer } from 'mobx-react-lite';
import React, { FC, useContext } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import AssessmentsStore from '../../stores/AssessmentsStore';
import TestPanel from '../TestPanel';
import styles from './TestPanels.module.css';

const TestPanels: FC = observer(() => {
  const { assessments } = useContext(AssessmentsStore);
  return (
    <div className={styles.default}>
      <TransitionGroup>
        {assessments.map(assessment => {
          if (assessment.isRunning || assessment.completedAt) {
            return (
              <CSSTransition key={assessment.name} timeout={300} classNames="fade">
                <TestPanel assessment={assessment} />
              </CSSTransition>
            );
          }
          return null;
        })}
      </TransitionGroup>
    </div>
  );
});

export default TestPanels;
