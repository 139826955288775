import React, { Fragment } from 'react';

import { AssessmentListing, KillChain } from '../../models/Assessment';
import { stealthyCommsKnown } from '../helpers/stealthy';
import { doDnsAssessment } from '../helpers';
import media from '../../assets/StealthyCommunicationsOfKnownMalware.jpg';

const assessment: AssessmentListing = {
  category: KillChain.C2,
  name: 'Stealthy Communications of Known Malware',
  media,
  priority: -Infinity, // this test pegs the network, start it last
  longDesc: (
    <Fragment>
      <p>This test simulates the C2 communications of known malware over the DNS protocol.</p>
      <ul>
        <li>
          IronNet does not execute any malicious programs, nor send any actual malicious
          communications or private data during this test.
        </li>
      </ul>
    </Fragment>
  ),
  shortDesc:
    'This test simulates the command and control (C2) communications of known malware over the DNS protocol.',
  characteristics:
    'C2 communications over DNS are performed using the Alma Trojan method to send encoded text data. The Alma protocol is detected by some security products using existing signatures.',
  warnings: {
    ironnet: [
      'Transmit simulated malware tasking commands and output to our web server over HTTPS using aperiodic communications.'
    ],
    attacker: [
      'Use a similar C2 communications protocol to steal proprietary information.',
      'Use a similar C2 communications protocol to obtain further internal network access.',
      'Use a similar C2 communications protocol to enable compromise of critical internal servers.',
      'Use a similar C2 communications protocol to attack other enterprise networks.',
      'Use a similar C2 communications protocol to maintain long-term access to otherwise protected systems.'
    ]
  },
  why:
    'Malware often uses the DNS protocol to send encoded text data (file contents, other system information, etc.) from the victim to the C2 server. This behavior is a common method for more advanced malware to evade detection of C2 communications.',
  how:
    'IronDefense can identify when legitimate features of the DNS protocol are abused to provide a covert communication channel between your network and likely malicious C2 servers. This behavior is extremely difficult to detect, as data can be hidden in DNS messages using encoded subdomain labels and resource records, and then transferred through the normal domain name resolution process.',
  doAssessment: () => {
    const [endpoints, id] = stealthyCommsKnown();
    return doDnsAssessment(endpoints, id, 1);
  }
};

export default assessment;
