import React, { Fragment } from 'react';

import {
  AssessmentListing,
  KillChain,
  AssessmentResponse,
  Risk,
  getTestResult
} from '../../models/Assessment';
import { doIntervalAssessment } from '../helpers';
import media from '../../assets/EncryptedMalwareCommunications.jpg';
import api from '../../api';

async function doAssessment(): Promise<AssessmentResponse> {
  let result = { risk: Risk.Low };
  const id = Math.floor(Math.random() * 10000);
  const endpoints: string[] = [];

  for (let i = 0; i < 10; i++) {
    endpoints.push(`${process.env.REACT_APP_C2_06}/t/${i}/task.php?id=${id}`);
  }

  const finalEndpoint = endpoints.pop();

  if (!finalEndpoint) {
    return result;
  }

  await doIntervalAssessment({ endpoints, interval: 11000 });

  const taskingRes = await api.get(finalEndpoint);

  if (taskingRes.data.tasking === 'ps auxwww') {
    const { data: blob } = await api.get(`${process.env.REACT_APP_C2_06}/psoutput`, {
      responseType: 'blob'
    });

    try {
      const postRes = await api({
        url: `${process.env.REACT_APP_C2_06}/t/output.php?id=${id}`,
        method: 'POST',
        data: blob
      });
      result = getTestResult(postRes);
    } catch (err) {
      console.warn(err);
    }
  }

  return result;
}

const assessment: AssessmentListing = {
  category: KillChain.C2,
  name: 'Encrypted Malware Communications',
  media,
  priority: Infinity, // this test is slow, start it first
  longDesc: (
    <Fragment>
      <p>This test sends data via HTTPS to a C2 server in an aperiodic manner.</p>
      <ul>
        <li>
          IronNet does not execute any malicious programs, nor send any actual malicious
          communications or private data during this test.
        </li>
      </ul>
    </Fragment>
  ),
  shortDesc:
    'This test simulates the periodic command and control (C2) of malware over the encrypted HTTPS protocol.',
  characteristics:
    'HTTPS connections between the browser and C2 infrastructure are controlled by an algorithm that makes the timing of connections appear random. Data transmitted in the connections looks similar to queries made by known malware.',
  warnings: {
    ironnet: [
      'Transmit simulated malware tasking commands and output to our web server over HTTPS using aperiodic communications.'
    ],
    attacker: [
      'Use a similar C2 communications protocol to steal proprietary information.',
      'Use a similar C2 communications protocol to obtain further internal network access.',
      'Use a similar C2 communications protocol to enable compromise of critical internal servers.',
      'Use a similar C2 communications protocol to attack other enterprise networks.',
      'Use a similar C2 communications protocol to maintain long-term access to otherwise protected systems.'
    ]
  },
  why:
    'Sophisticated attackers add a layer of obfuscation to periodic beaconing by adding “jitter” in order to avoid detection. Aperiodic beaconing is designed to mimic human behavior and defeat tools that rely on periodicity for detection.',
  how:
    'IronDefense analytics can identify aperiodic beaconing used by sophisticated malware and with our Expert System can differentiate between benign and malicious activity.',
  doAssessment
};

export default assessment;
