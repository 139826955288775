import React, { Fragment } from 'react';

import { AssessmentListing, KillChain } from '../../models/Assessment';
import { doHashCompareAssessment } from '../helpers';
import media from '../../assets/UnknownMalwareDownload.jpg';

const assessment: AssessmentListing = {
  category: KillChain.ACCESS,
  name: 'Unknown Malware Download',
  media,
  longDesc: (
    <Fragment>
      <p>
        This test downloads an inert version of a wiper program for which there is no existing
        threat intel signature from a suspicious website over HTTPS.
      </p>
      <ul>
        <li>
          IronNet does not download any working malicious programs, or execute any programs on the
          user’s computer.
        </li>
        <li>
          The domain used for this test is fully owned by IronNet and is not actually malicious, but
          has been intentionally created to resemble a phishing attack. IronNet does not collect or
          send any sensitive data during this test.
        </li>
      </ul>
    </Fragment>
  ),
  shortDesc:
    'This test simulates the act of downloading unknown malware from a suspicious website over the encrypted HTTPS protocol.',
  characteristics:
    'A normal HTTPS connection is created to a new domain which may appear suspicious to an attentive user but has not been flagged as malicious. This connection is then used to transfer an unknown executable.',
  warnings: {
    ironnet: ['Download an inert version of a non-signature wiper program using the browser.'],
    attacker: [
      'Potentially trick users into downloading an unknown ransomware program.',
      'Ransomware and wiper programs have caused catastrophic damage to businesses by disabling their IT infrastructure.',
      'Users may run downloaded malware, giving the attacker access to company computers, servers, and other infrastructure.'
    ]
  },
  why:
    'Attackers often modify existing malware to evade signature-based detection, and then use social engineering techniques to trick users into downloading the new malware from suspicious websites.',
  how:
    'IronDefense anomaly and behavior-based analytics can identify websites that are unlikely or uncommon for your network to visit and can identify behaviors associated with suspicious file downloads whether or not signatures exist.',
  doAssessment: () => doHashCompareAssessment(`${process.env.REACT_APP_ACCESS_04}/wintimecheck.exe`)
};

export default assessment;
