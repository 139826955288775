import cc from 'classcat';
import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';
import 'react-slidedown/lib/slidedown.css';

import Heading from '../Heading';
import styles from './ResultPanel.module.css';
import Icon from '../Icon';
import Tooltip from '../Tooltip';

interface ResultPanelProps {
  category: string;
  title?: string;
  success: number;
  total: number;
  msg: string;
  text: string;
  tooltip: string;
}

const ResultPanel: FC<ResultPanelProps> = observer(
  ({ title, category, success, total, msg, text, tooltip }) => (
    <div className={cc([styles.default])}>
      <div className={styles.tooltip}>
        <Heading tag="h3" className={styles.heading}>
          {title || category}
        </Heading>
        <Tooltip tipText={tooltip} />
      </div>
      <Icon
        mode="pill"
        type={success > 0 ? 'high' : 'low'}
        title={success > 0 ? 'Successful Test' : 'Unsuccessful Test'}
        text={text}
        wrap="wrapPill"
      />
      <div className={styles.desc}>
        In {success} of {total} {msg}
      </div>
    </div>
  )
);

export default ResultPanel;
