import React, { Fragment } from 'react';

import { AssessmentListing, KillChain } from '../../models/Assessment';
import { doHashCompareAssessment } from '../helpers';
import media from '../../assets/KnownMalwareDownload.jpg';

const assessment: AssessmentListing = {
  category: KillChain.ACCESS,
  name: 'Ransomware Download',
  media,
  longDesc: (
    <Fragment>
      <p>
        This test downloads an inert version of a publicly-known ransomware/wiper program from the
        Shamoon/StoneDrill campaigns from a suspicious website over HTTPS.
      </p>
      <ul>
        <li>
          IronNet does not download any working malicious programs, or execute any programs on the
          user’s computer.
        </li>
        <li>
          The domain used for this test is fully owned by IronNet and is not actually malicious, but
          has been intentionally created to resemble a phishing attack. IronNet does not collect or
          send any sensitive data during this test.
        </li>
      </ul>
    </Fragment>
  ),
  shortDesc:
    'This test simulates the act of downloading known malware from a suspicious website over the encrypted HTTPS protocol.',
  characteristics:
    'A normal HTTPS connection is created to a new domain which may appear suspicious to an attentive user but has not been flagged as malicious. This connection is then used to transfer a known malware executable.',
  warnings: {
    ironnet: ['Download an inert version of a known ransomware/wiper program using the browser.'],
    attacker: [
      'Potentially trick users into downloading a known ransomware program.',
      'Ransomware and wiper programs have caused catastrophic damage to businesses by disabling their IT infrastructure.',
      'Users may run downloaded malware, giving the attacker access to company computers, servers, and other infrastructure.'
    ]
  },
  why:
    'Attackers often use social engineering techniques to trick users into downloading known malware from suspicious websites. Keeping threat intel up-to-date and deploying it effectively is nearly impossible.',
  how:
    'IronDefense analytics can identify websites that are unlikely or uncommon for your network to visit, and can identify behaviors associated with suspicious file downloads, independent of the file hash or website from which it was downloaded – even when the communication is encrypted.',
  doAssessment: () =>
    doHashCompareAssessment(`${process.env.REACT_APP_ACCESS_03}/CloudServiceClient.exe`)
};

export default assessment;
