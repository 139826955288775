import cc from 'classcat';
import React, { FC, HTMLProps, ReactElement } from 'react';

import styles from './Button.module.css';

interface ButtonProps extends HTMLProps<HTMLButtonElement> {
  type?: 'button' | 'submit' | 'reset';
  className?: 'text' | 'link' | 'primary' | 'secondary' | 'fab';
  icon?: React.ReactElement;
  iconPlacement?: 'left' | 'right';
  isLink?: boolean;
  href?: string;
}

/* eslint-disable react/button-has-type */
const Button: FC<ButtonProps> = ({
  children,
  className,
  type = 'button',
  icon,
  iconPlacement = 'left',
  isLink = false,
  href,
  ...rest
}): ReactElement => {
  if (isLink) {
    return (
      <a
        className={cc([className && styles[className], iconPlacement && styles[iconPlacement]])}
        href={href}
        target="_blank"
        rel="noopener noreferrer"
      >
        {icon && <span className={styles.icon}>{icon}</span>}
        <span className={styles.content}>{children}</span>
      </a>
    );
  }
  return (
    <button
      type={type}
      className={cc([className && styles[className], iconPlacement && styles[iconPlacement]])}
      {...rest}
    >
      {icon && <span className={styles.icon}>{icon}</span>}
      <span className={styles.content}>{children}</span>
    </button>
  );
};
/* eslint-enable react/button-has-type */

export default Button;
