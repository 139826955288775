import { distanceInWords, format } from 'date-fns';
import { action, computed, observable } from 'mobx';
import { createContext } from 'react';
import URLSearchParams from '@ungap/url-search-params';

import assessmentList from '../assessments';
import Assessment, { AssessmentListing, KillChain } from '../models/Assessment';
import { checkPulse } from '../utils';
import { beacon, source } from '../api';

const dateFormat = 'MMM D, YYYY hh:mm:ss A';
const testLag = Number(process.env.REACT_APP_TEST_LAG);

export interface SummaryTotals {
  total: number;
  highRisk: number;
  lowRisk: number;
  warnings: number;
  access: number;
  c2: number;
  action: number;
  accessTotal: number;
  c2Total: number;
  actionTotal: number;
}

export type TestFilters = { [K in 'only' | 'skip']: RegExp[] };

export class Assessments {
  @observable
  public isAlive: boolean | null = null;

  @observable
  public assessments: Assessment[] = [];

  @observable
  private _testsStartedAt: Date | null = null;

  @observable
  private _testsFinishedAt: Date | null = null;

  public static getTestFilters(): TestFilters {
    // eslint-disable-next-line no-restricted-globals
    const params = new URLSearchParams(window.location.search);
    return ['only', 'skip'].reduce((acc: Partial<TestFilters>, key) => {
      const patterns = (params.get(key) || '').split(',').filter(Boolean);
      acc[key as keyof TestFilters] = patterns.map(p => new RegExp(p, 'i'));
      return acc;
    }, {}) as TestFilters;
  }

  public static evaluateTestFilters(
    { category, name }: Assessment,
    { only, skip }: TestFilters
  ): boolean {
    const matchesOnly = only.length < 1 || only.some(re => re.test(category) || re.test(name));
    const matchesSkip = skip.length > 0 && skip.some(re => re.test(category) || re.test(name));
    return matchesOnly && !matchesSkip;
  }

  public constructor(assessments: AssessmentListing[]) {
    const filters = Assessments.getTestFilters();
    this.assessments = assessments.reduce((acc: Assessment[], data) => {
      const assessment = new Assessment(data);
      if (assessment.enabled && Assessments.evaluateTestFilters(assessment, filters)) {
        acc.push(assessment);
      }
      return acc;
    }, []);
  }

  public get testsStartedAt(): string | null {
    return this._testsStartedAt && format(this._testsStartedAt, dateFormat);
  }

  public get testsFinishedAt(): string | null {
    return this._testsFinishedAt && format(this._testsFinishedAt, dateFormat);
  }

  @computed
  public get testsDuration(): string | null {
    if (this._testsStartedAt && this._testsFinishedAt) {
      return distanceInWords(this._testsStartedAt, this._testsFinishedAt);
    }
    return null;
  }

  @computed
  public get percentComplete(): number {
    if (!this.testsStartedAt) return 0;
    if (this.assessments.length && this.completedAssessments) {
      return (this.completedAssessments / this.assessments.length) * 100;
    }
    return 1;
  }

  @computed
  public get completedAssessments(): number {
    return this.assessments.filter(assessment => assessment.completedAt).length;
  }

  @computed
  public get summaryTotals(): SummaryTotals {
    const summary: SummaryTotals = {
      total: 0,
      highRisk: 0,
      lowRisk: 0,
      warnings: 0,
      access: 0,
      c2: 0,
      action: 0,
      accessTotal: 0,
      c2Total: 0,
      actionTotal: 0
    };

    return this.assessments.reduce(
      (accum, assessment) => ({
        total: accum.total + 1,
        highRisk: accum.highRisk + Number(assessment.risk === 'high'),
        lowRisk: accum.lowRisk + Number(assessment.risk === 'low'),
        warnings: accum.warnings + assessment.warningCount,
        access:
          accum.access +
          Number(assessment.category === KillChain.ACCESS && assessment.risk === 'high'),
        c2: accum.c2 + Number(assessment.category === KillChain.C2 && assessment.risk === 'high'),
        action:
          accum.action +
          Number(assessment.category === KillChain.ACTION && assessment.risk === 'high'),
        accessTotal: accum.accessTotal + Number(assessment.category === KillChain.ACCESS),
        c2Total: accum.c2Total + Number(assessment.category === KillChain.C2),
        actionTotal: accum.actionTotal + Number(assessment.category === KillChain.ACTION)
      }),
      summary
    );
  }

  @action
  public runTests = async (): Promise<void> => {
    this._testsStartedAt = new Date();
    this._testsFinishedAt = null;

    const assessments = this.assessments.slice().sort((a, b) => b.priority - a.priority);
    const promises: Promise<unknown>[] = [];

    beacon('start');

    for (const assessment of assessments) {
      // eslint-disable-next-line no-await-in-loop
      await new Promise(r => setTimeout(r, testLag));
      promises.push(assessment.startTest());
    }

    try {
      await Promise.all(promises);
    } finally {
      beacon('complete');
    }

    this.finishTests();
  };

  @action
  public cancelTests = (): void => {
    source.cancel();
    this.assessments.forEach(assessment => assessment.cancelTest());
    this.finishTests();
  };

  @action
  private finishTests = (): void => {
    this._testsFinishedAt = new Date();
  };

  public checkPulse = async (): Promise<void> => {
    try {
      this.isAlive = await checkPulse();
      return Promise.resolve();
    } catch (e) {
      console.error(e);
      this.isAlive = false;
      return Promise.reject();
    }
  };
}

export default createContext(new Assessments(Object.values(assessmentList)));
