import React, { FC, ReactElement } from 'react';

import styles from './Loading.module.css';

interface LoadingProps {
  width?: string;
}

const Loading: FC<LoadingProps> = ({ width = '3em' }): ReactElement => (
  <svg viewBox="25 25 50 50" className={styles.default} style={{ width }}>
    <circle cx="50" cy="50" r="20" />
  </svg>
);

export default Loading;
