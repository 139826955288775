import { AxiosRequestConfig } from 'axios';
import { eachOfLimit } from 'async';
import { sha256 } from 'js-sha256';
import { noop, once } from 'lodash';

import { AssessmentResponse, getTestResult, Risk } from '../../models/Assessment';
import api from '../../api';

const chunkSize = Number(process.env.REACT_APP_UPLOAD_CHUNK_SIZE);
const uploadConcurrency = Number(process.env.REACT_APP_UPLOAD_CONCURRENCY);

/**
 * Hash an ArrayBuffer and compare against a provided SHA256 hash.
 * @param {ArrayBuffer} downloaded - The downloaded binary's ArrayBuffer
 * @param {string} headerHash - SHA256 hash to compare against
 * @return {boolean}
 */
function compareHashes(downloaded: ArrayBuffer, headerHash: string): boolean {
  return sha256(downloaded) === headerHash;
}

/**
 * Run a "hash comparison" assessment.
 * @param {string} endpoint - The URL endpoint to fetch
 * @return {Promise}
 */
export function doHashCompareAssessment(endpoint: string): Promise<AssessmentResponse> {
  return new Promise(async resolve => {
    const result = { risk: Risk.Low };

    try {
      const response = await api.get(endpoint, {
        responseType: 'arraybuffer'
      });
      if (compareHashes(response.data, response.headers['x-sha256'])) {
        result.risk = Risk.High;
      }
    } catch (e) {
      console.warn(e);
    }

    resolve(result);
  });
}

/**
 * Run a "rendezvous" assessment.
 * @param {string[]} domains - Array of domain names to be fetched
 * @param {string} finalDomain - Last domain name to be fetched (to verify entire test)
 * @return {Promise}
 */
export function doRendezvousAssessment(
  domains: string[],
  finalDomain: string
): Promise<AssessmentResponse> {
  return new Promise(async resolve => {
    let result = { risk: Risk.Low };

    await Promise.all(
      domains.map(domain => api({ url: `https://${domain}`, method: 'OPTIONS' }).catch(noop))
    );

    try {
      const response = await api.get(finalDomain);
      result = getTestResult(response);
    } catch (e) {
      result.risk = Risk.Low;
    }

    resolve(result);
  });
}

export interface IntervalAssessmentOptions {
  method?: 'GET' | 'POST';
  endpoints: string[];
  interval?: number;
}

/**
 * Replace various placeholders in a given URL.
 * @param {string} url - The URL to be processed
 * @return {string}
 */
function processUrl(url: string): string {
  return url.replace('<time>', Date.now().toString());
}

/**
 * Process and individual request.
 * @param {AxiosRequestConfig} request - Axios request configuration
 */
async function processRequest(request: AxiosRequestConfig): Promise<AssessmentResponse> {
  const axiosRequest = { ...request, url: processUrl(request.url || '') };

  return new Promise(async resolve => {
    try {
      const response = await api(axiosRequest);
      resolve(getTestResult(response));
    } catch (e) {
      resolve({ risk: Risk.Low });
    }
  });
}

/**
 * Run a series of requests synchronously.
 * @param {IntervalAssessmentOptions} options - Options for all requests
 * @return {Promise}
 */
export async function doIntervalAssessment({
  method = 'GET',
  interval = 1000,
  endpoints
}: IntervalAssessmentOptions): Promise<AssessmentResponse> {
  let risk = Risk.Low;
  for (const url of endpoints) {
    // eslint-disable-next-line no-await-in-loop
    const res = await processRequest({ url, method });
    if (res.risk === Risk.High) {
      risk = Risk.High;
    }
    // eslint-disable-next-line no-await-in-loop
    await new Promise(r => setTimeout(r, interval));
  }
  return { risk };
}

/**
 * Run a "DNS tunneling" assessment.
 * @param {string[]} endpoints - Array of viable subdomain prefixes
 * @param {string} id - Identifier for the collection of requests
 * @param {number} t - Identifier for the last endpoint to hit
 * @return {Promise}
 */
export function doDnsAssessment(
  endpoints: string[],
  id: string,
  t: number
): Promise<AssessmentResponse> {
  return new Promise(async resolve => {
    const requests = endpoints.map(endpoint =>
      api({ url: `https://${endpoint}`, method: 'OPTIONS' }).catch(noop)
    );

    await Promise.all(requests);

    const response = await api.get(
      `${process.env.REACT_APP_API_BASE_URL}/check.php?id=${id}&t=${t}`
    );

    resolve(getTestResult(response));
  });
}

const fetchDataTheftPayload = once(
  async (): Promise<Blob> => {
    const { data } = await api.get(`${process.env.REACT_APP_API_BASE_URL}/srctar`, {
      responseType: 'blob',
      timeout: 18e4 // 3m
    });
    return data;
  }
);

/**
 * Run a "data theft" assessment.
 * @param {string} uploadPath - The base URL path to upload the "stolen" data to.
 * @return {Promise}
 */
export async function doDataTheftAssessment(uploadPath: string): Promise<AssessmentResponse> {
  const blob = await fetchDataTheftPayload();
  const { size } = blob;
  const chunkBounds: [number, number][] = [];

  for (let start = 0; start < size; start += chunkSize) {
    const end = start + chunkSize > size ? size : start + chunkSize;
    chunkBounds.push([start, end]);
  }

  let risk = Risk.High;

  return new Promise(resolve => {
    eachOfLimit(
      chunkBounds,
      uploadConcurrency,
      async ([start, end], index, cb) => {
        const n = Number(index) + 1;
        const chunk = blob.slice(start, end);

        try {
          const uploadUrl = uploadPath.replace('<count>', String(n));
          const { data } = await api({
            url: uploadUrl,
            method: 'post',
            data: chunk
          });

          if (data.status !== 'success') {
            risk = Risk.Low;
          }

          cb(null);
        } catch (err) {
          cb(err);
        }
      },
      () => resolve({ risk })
    );
  });
}

export default { doHashCompareAssessment };
