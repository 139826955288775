import React, { FC, HTMLProps, ReactElement } from 'react';

import { ReactComponent as IronNetLogo } from '../../assets/ironnet-logo.svg';
import { HOME_URL } from '../../urls';
import Container from '../Container';
import Heading from '../Heading';
import styles from './Header.module.css';

interface HeaderProps extends HTMLProps<HTMLDivElement> {}

const Header: FC<HeaderProps> = (): ReactElement => (
  <header className={styles.header}>
    <Container>
      <div className={styles.inner}>
        <Heading tag="h1" className={styles.title}>
          Cyber Assessment Tool
        </Heading>
        <a className={styles.logo} href={HOME_URL} target="_blank" rel="noopener noreferrer">
          <span className={styles.powered}>Powered by:</span>
          <figure className={styles['logo-wrap']}>
            <IronNetLogo className={styles['logo-image']} />
          </figure>
        </a>
      </div>
    </Container>
  </header>
);

export default Header;
