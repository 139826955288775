import React, { Fragment } from 'react';

import { AssessmentListing, KillChain } from '../../models/Assessment';
import { stealthyCommsUnknown } from '../helpers/stealthy';
import { doDnsAssessment } from '../helpers';
import media from '../../assets/StealthyCommunicationsOfUnknownMalware.jpg';

const assessment: AssessmentListing = {
  category: KillChain.C2,
  name: 'Stealthy Communications of Unknown Malware',
  media,
  priority: -Infinity, // this test pegs the network, start it last
  longDesc: (
    <Fragment>
      <p>
        This test sends C2 communications over the DNS protocol using a custom, non-signatured
        encoding method.
      </p>
      <ul>
        <li>
          IronNet does not execute any malicious programs, nor send any actual malicious
          communications or private data during this test.
        </li>
      </ul>
    </Fragment>
  ),
  shortDesc:
    'This test simulates the command and control (C2) communications of unknown malware over the DNS protocol.',
  characteristics:
    'C2 communications over DNS are performed using a custom method to encode text data.',
  warnings: {
    ironnet: [
      'Send DNS requests containing encoded data using a custom encoding scheme that is similar to methods used by known malware to transmit data to a C2 server.'
    ],
    attacker: [
      'Use a similar C2 communications protocol to steal proprietary information.',
      'Use a similar C2 communications protocol to obtain further internal network access.',
      'Use a similar C2 communications protocol to enable compromise of critical internal servers.',
      'Use a similar C2 communications protocol to attack other enterprise networks.',
      'Use a similar C2 communications protocol to maintain long-term access to otherwise protected systems.'
    ]
  },
  why:
    'Adversaries are not limited to using known techniques or encoding schemes, and typically exploit those protocols that are critical and common for network operation (DNS, HTTPS).  Identifying abuse of these protocols is essential when identifying advanced threats in your network.',
  how:
    'Whether an adversary uses known or unknown techniques to communicate out of your network, IronDefense analytics can identify when legitimate protocols are abused for malicious purposes. No signatures needed!',
  doAssessment: () => {
    const [endpoints, id] = stealthyCommsUnknown();
    return doDnsAssessment(endpoints, id, 4);
  }
};

export default assessment;
