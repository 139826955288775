import { RefObject, useEffect } from 'react';

import api from './api';

/**
 * Hook for handling clicks outside of a given element.
 *
 * @param ref The reference element relative to the click event.
 * @param cb The callback to be executed when an outside click occurs.
 */
export function useClickOutside(ref: RefObject<HTMLElement>, cb: () => void): void {
  const handleClickOutside = (e: Event): void => {
    if (ref.current && !ref.current.contains(e.target as Node)) {
      cb();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, false);
    return () => {
      document.removeEventListener('click', handleClickOutside, false);
    };
  });
}

/**
 * Determine if the API is currently available.
 *
 * @returns {Promise} If Promise resolves, API is available.
 */
export async function checkPulse(): Promise<boolean> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await api.get(`${process.env.REACT_APP_API_BASE_URL}/alive`);
      if (response.data) {
        resolve(true);
      }
    } catch (e) {
      reject(e);
    }
  });
}
