import cc from 'classcat';
import React, { FC, ReactElement } from 'react';
import SlideDown from 'react-slidedown';

import Container from '../Container';
import styles from './Progress.module.css';

interface ProgressProps {
  percent?: number;
  duration: string | null;
}

const Progress: FC<ProgressProps> = ({ percent = 0, duration }): ReactElement => (
  <SlideDown className="slider" closed={percent === 100}>
    <div className={cc([styles.default, percent < 100 && styles.running])}>
      <Container>
        <p className={styles.caption}>
          {duration ? (
            <span>Cyber Assessment completed in {duration}.</span>
          ) : (
            <span>
              Your cyber assessment will take a few minutes to process. Please wait&hellip;
            </span>
          )}
        </p>
        <div className={styles.wrap}>
          <div className={styles.bar}>
            <div className={styles.progress} style={{ width: `${percent}%` }} />
          </div>
          <div className={styles.percent}>{Math.floor(percent)}%</div>
        </div>
      </Container>
    </div>
  </SlideDown>
);

export default Progress;
