import { observer } from 'mobx-react-lite';
import React, { FC, useContext } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import AssessmentsStore from '../../stores/AssessmentsStore';
import ResultPanel from '../ResultPanel';
import styles from './ResultPanels.module.css';

interface CategorySummary {
  success: number;
  total: number;
  message: string;
  text: string;
  title?: string;
  tooltip: string;
}

interface Summaries {
  [key: string]: CategorySummary;
}

const ResultPanels: FC = observer(() => {
  const { assessments, testsFinishedAt, summaryTotals } = useContext(AssessmentsStore);
  const categories = [...new Set(assessments.map(assessment => assessment.category))];
  const summaries: Summaries = {
    ACCESS: {
      success: summaryTotals ? summaryTotals.access : 0,
      total: summaryTotals ? summaryTotals.accessTotal : 0,
      message:
        'Access test scenarios, simulated sensitive information was successfully transmitted out of your environment and/or inert malware was successfully transmitted in using techniques commonly associated with Phishing or Ransomware campaigns.',
      text: `${summaryTotals.access} tests`,
      tooltip: 'The intruder has accessed the target’s network.'
    },
    C2: {
      title: 'Command and Control (C2)',
      success: summaryTotals ? summaryTotals.c2 : 0,
      total: summaryTotals ? summaryTotals.c2Total : 0,
      message:
        'C2 test scenarios, communications were successfully established between your browser and an external command and control site using techniques employed by numerous malware families.',
      text: `${summaryTotals.c2} tests`,
      tooltip: 'The intruder has "hands on the keyboard" persistent access to the target network.'
    },
    ACTION: {
      success: summaryTotals ? summaryTotals.action : 0,
      total: summaryTotals ? summaryTotals.actionTotal : 0,
      message:
        'Action test scenarios, large amounts of simulated sensitive Intellectual Property were successfully transmitted out of your environment in a manner similar to that used in publicly disclosed data breaches.',
      text: `${summaryTotals.action} tests`,
      tooltip: 'The intruder can exfiltrate data, destroy data or encrypt data for ransom.'
    }
  };
  return (
    <TransitionGroup className={styles.sidebar}>
      {categories.map(category => {
        const { success, total, message, text, title, tooltip } = summaries[category];
        if (testsFinishedAt) {
          return (
            <CSSTransition key={category} timeout={300} classNames="fade">
              <ResultPanel
                title={title}
                category={category}
                success={success}
                total={total}
                msg={message}
                text={text}
                tooltip={tooltip}
              />
            </CSSTransition>
          );
        }
        return null;
      })}
    </TransitionGroup>
  );
});

export default ResultPanels;
