import React, { FC, ReactElement, Fragment } from 'react';

import Heading from '../Heading';
import Icon from '../Icon';
import Footer from '../Footer';
import styles from './HesDeadJim.module.css';
import Loading from '../Loading';

interface HesDeadJimProps {
  isLoading?: boolean;
}

const HesDeadJim: FC<HesDeadJimProps> = ({ isLoading = false }): ReactElement => (
  <Fragment>
    <div className={styles.default}>
      {isLoading ? (
        <div>
          <Loading width="5em" />
          <Heading tag="h2">Loading&hellip;</Heading>
        </div>
      ) : (
        <div>
          <div className={styles.title}>
            <Icon type="warning" wrap="wrap" />
            <Heading tag="h2">Temporarily Unavailable</Heading>
          </div>
          <Heading tag="h4">
            IronNet&apos;s <em>Cyber Assessment Tool</em> is temporarily unavailable. Please try
            again later.
          </Heading>
        </div>
      )}
    </div>
    <Footer />
  </Fragment>
);

export default HesDeadJim;
