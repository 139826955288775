import cc from 'classcat';
import React, { FC, ReactElement } from 'react';

import { ReactComponent as CheckIcon } from '../../assets/Icon/Check.svg';
import { ReactComponent as SkullIcon } from '../../assets/Icon/Skull.svg';
import { ReactComponent as WarningIcon } from '../../assets/Icon/Warning.svg';
import styles from './Icon.module.css';

interface IconProps {
  type: 'high' | 'low' | 'warning' | 'check';
  mode?: 'small' | 'medium' | 'large' | 'pill';
  bubble?: boolean;
  title?: string;
  text?: string;
  wrap?: string;
}

const icons = {
  high: <SkullIcon />,
  low: <SkullIcon />,
  warning: <WarningIcon />,
  check: <CheckIcon />
};

const Icon: FC<IconProps> = ({
  type,
  mode = 'default',
  bubble = false,
  title = '',
  text = '',
  wrap = ''
}): ReactElement => (
  <span className={cc([styles[type], styles[mode], bubble && styles.bubble])} title={title}>
    <span className={styles[wrap]}>{icons[type]} </span> <span className={styles.text}>{text}</span>
  </span>
);

export default Icon;
