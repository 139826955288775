import React, { FC, ReactElement } from 'react';

import { ReactComponent as IronNetLogo } from '../../assets/ironnet-logo-light.svg';
import * as siteUrls from '../../urls';
import Container from '../Container';
import styles from './Footer.module.css';

interface FooterProps {}

const Footer: FC<FooterProps> = (): ReactElement => (
  <footer className={styles.default}>
    <Container>
      <div className={styles.colophon}>
        <a
          className={styles.logo}
          href={siteUrls.HOME_URL}
          target="_blank"
          rel="noopener noreferrer"
        >
          <IronNetLogo />
        </a>
        <p>
          As the nation that created the Internet, it is our belief that it is our mission to secure
          it. We set out on creating a capability to do just that by founding IronNet Cybersecurity
          with an unmatched team of experts from government, industry and academia.
        </p>
      </div>
      <div className={styles.fine}>
        <div>
          <p>&copy; {new Date().getFullYear()} IronNet Cybersecurity Inc. All Rights Reserved</p>
        </div>
        <div>
          <ul className={styles.links}>
            <li className={styles.item}>
              <a href={siteUrls.HOME_URL} target="_blank" rel="noopener noreferrer">
                Home
              </a>
            </li>
            <li className={styles.item}>
              <a href={siteUrls.PRIVACY_URL} target="_blank" rel="noopener noreferrer">
                Privacy Statement
              </a>
            </li>
            <li className={styles.item}>
              <a href={siteUrls.TERMS_URL} target="_blank" rel="noopener noreferrer">
                Terms of Service
              </a>
            </li>
            <li className={styles.item}>
              <a href={siteUrls.SITEMAP_URL} target="_blank" rel="noopener noreferrer">
                Sitemap
              </a>
            </li>
          </ul>
        </div>
      </div>
    </Container>
  </footer>
);

export default Footer;
