import React, { FC, useRef, ReactElement } from 'react';
import cc from 'classcat';
import ScrollLock from 'react-scrolllock';

import { ReactComponent as Close } from '../../assets/Icon/Close.svg';
import { useClickOutside } from '../../utils';
import Button from '../Button';
import styles from './Modal.module.css';

interface ModalProps {
  onClose: () => void;
  useOkCloseButton?: boolean;
  width?: 'medium' | 'small';
}

const Modal: FC<ModalProps> = ({
  children,
  onClose,
  useOkCloseButton = false,
  width = 'medium'
}): ReactElement => {
  const contentRef = useRef(null);
  useClickOutside(contentRef, onClose);

  return (
    <ScrollLock isActive>
      <div className={styles.default}>
        <div className={cc([styles.content, styles[`${width}Width`]])} ref={contentRef}>
          {children}
          {useOkCloseButton ? (
            <div className={styles.okCloseButton}>
              <Button className="text" onClick={onClose}>
                OK
              </Button>
            </div>
          ) : (
            <div className={styles.xCloseButton}>
              <Button className="text" onClick={onClose}>
                <Close />
              </Button>
            </div>
          )}
        </div>
      </div>
    </ScrollLock>
  );
};

export default Modal;
