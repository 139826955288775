import React, { Fragment } from 'react';

import { AssessmentListing, KillChain } from '../../models/Assessment';
import media from '../../assets/SensitiveUserInformationTheft.jpg';

const assessment: AssessmentListing = {
  category: KillChain.ACCESS,
  name: 'Sensitive User Information Theft',
  media,
  longDesc: (
    <Fragment>
      <p>
        This test simulates the theft of a user’s Personally Identifiable Information (PII) via a
        phishing website over the encrypted HTTPS protocol.
      </p>
      <ul>
        <li>
          The domain used for this test is fully owned by IronNet and is not actually malicious, but
          has been intentionally created to resemble a phishing attack. IronNet does not collect or
          send any sensitive data during this test.
        </li>
      </ul>
    </Fragment>
  ),
  shortDesc:
    'This test simulates the theft of a user’s Personally Identifiable Information (PII) via a phishing website over the encrypted HTTPS protocol.',
  characteristics:
    'A normal HTTPS connection is created to a new domain which may appear suspicious to an attentive user but has not been flagged as malicious. This connection is then used to transfer Credentials and PII.',
  warnings: {
    ironnet: ['Transmit simulated sensitive user information to a suspicious-looking domain.'],
    attacker: [
      'Phish user credentials or steal other sensitive user data using a website that spoofs a commonly known brand.',
      'Use stolen credentials or PII to further target internal systems, people, or other companies.'
    ]
  },
  why:
    'Phishing is one of the most common initial attack vectors because of the success it has for adversaries.  Many Security tools try to solve this problem by blocking phishing emails at the email server, but no tool is 100% effective, and all it takes is one user to take the bait.',
  how:
    'IronDefense analytics can detect data exfiltration attempts that use high volume and/or high density communications. We focus on data exfiltration from internal computers to external sites,  by modelling clusters of like computers, and comparing individual computers to each other in their cluster.  This approach also identifies data exfiltration in encrypted traffic without the need to decrypt.',
  endpoint: `${process.env.REACT_APP_ACCESS_02}/b.php?user=testuser1&cn=1234567898765432&password=abc123`
};

export default assessment;
