import { AxiosResponse } from 'axios';
import { action, observable } from 'mobx';
import { ReactElement } from 'react';

import api from '../api';

export enum KillChain {
  RECON = 'RECON',
  C2 = 'C2',
  ACCESS = 'ACCESS',
  ACTION = 'ACTION'
}

export enum Risk {
  High = 'high',
  Low = 'low'
}

export interface AssessmentResponse {
  risk: Risk;
}

export interface AssessmentWarnings {
  ironnet: string[];
  attacker: string[];
}

export interface AssessmentListing {
  category: KillChain;
  name: string;
  longDesc: ReactElement;
  shortDesc: string;
  characteristics: string;
  warnings: AssessmentWarnings;
  why?: string;
  how?: string;
  enabled?: boolean;
  endpoint?: string;
  media?: string;
  priority?: number;
  doAssessment?: () => Promise<AssessmentResponse>;
}

export function getTestResult(response: AxiosResponse): AssessmentResponse {
  const { status } = response.data;
  return { risk: status === 'success' ? Risk.High : Risk.Low };
}

export interface AssessmentInterface extends AssessmentListing {
  category: KillChain;
  name: string;
  longDesc: ReactElement;
  shortDesc: string;
  characteristics: string;
  warnings: AssessmentWarnings;
  risk: Risk | null;
  ioc: string;
  endpoint?: string;
  doAssessment: () => Promise<AssessmentResponse>;
}

class Assessment implements AssessmentInterface {
  public category: KillChain;
  public name: string = '';
  public longDesc: ReactElement;
  public shortDesc: string = '';
  public characteristics: string = '';
  public ioc: string = '';
  public enabled: boolean = true;
  public endpoint: string = '';
  public media: string = '';
  public priority: number = 0;
  public warnings: AssessmentWarnings = { ironnet: [], attacker: [] };
  public why: string = '';
  public how: string = '';

  @observable
  public risk: Risk | null = null;

  @observable
  public isRunning: boolean = false;

  @observable
  public completedAt: Date | null = null;

  public constructor(props: AssessmentListing) {
    this.category = props.category;
    this.name = props.name;
    this.longDesc = props.longDesc;
    this.shortDesc = props.shortDesc;
    this.characteristics = props.characteristics;
    this.warnings = props.warnings;
    this.why = props.why || '';
    this.how = props.how || '';
    if (typeof props.enabled === 'boolean') {
      this.enabled = props.enabled;
    }
    if (typeof props.priority === 'number') {
      this.priority = props.priority;
    }
    if (props.endpoint) {
      this.endpoint = props.endpoint;
    }
    if (props.media) {
      this.media = props.media;
    }
    if (props.doAssessment) {
      this.doAssessment = props.doAssessment;
    }
  }

  /**
   * Default doAssessment
   *
   * If used, an endpoint argument *must* be provided to the constructor.
   */
  public async doAssessment(): Promise<AssessmentResponse> {
    return new Promise<AssessmentResponse>(async (resolve, reject) => {
      if (!this.endpoint) {
        throw new Error('An endpoint must be provided.');
      }
      try {
        const response = await api.get(this.endpoint);
        const result = getTestResult(response);

        resolve(result);
      } catch (e) {
        reject(new Error(e));
      }
    });
  }

  @action
  public async startTest(): Promise<void> {
    this.isRunning = true;
    this.completedAt = null;

    return new Promise(async resolve => {
      try {
        const result: AssessmentResponse = await this.doAssessment();
        this.risk = result.risk;
      } catch (e) {
        this.risk = Risk.Low;
        console.error(e);
      }

      this.finished();

      resolve();
    });
  }

  @action
  public cancelTest(): void {
    this.risk = Risk.Low;
    this.finished();
  }

  @action
  public finished(): void {
    this.isRunning = false;
    this.completedAt = new Date();
  }

  public get warningCount(): number {
    return [...this.warnings.attacker].length;
  }
}

export default Assessment;
