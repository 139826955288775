import axios from 'axios';
import axiosRetry from 'axios-retry';
import { observable } from 'mobx';

const MAX_CONCURRENT_REQUESTS = Number(process.env.REACT_APP_MAX_CONCURRENT_REQUESTS) || Infinity;
const pendingRequests = observable.box(0);
let inFlightRequests = 0;

export function beacon(type: string, data: object = {}): void {
  const timestamp = new Date().toISOString();
  navigator.sendBeacon(`/hooks/${type}`, JSON.stringify({ ...data, timestamp }));
}

export const source = axios.CancelToken.source();

const api = axios.create({
  timeout: 60e3
});

delete api.defaults.headers.post['Content-Type'];

axiosRetry(api, {
  retries: 3,
  // Retry anything except failed DNS lookups
  retryCondition: err => {
    // DNS failures won't have an error code
    const shouldRetry = Boolean(err.code);
    if (shouldRetry) {
      // eslint-disable-next-line no-console
      console.log(`${err.code} ${err.config.url} (retrying)`);
    }
    return shouldRetry;
  },
  retryDelay: count => count * 1000
});

api.interceptors.request.use(async config => {
  const index = pendingRequests.get();
  pendingRequests.set(index + 1);
  if (index > MAX_CONCURRENT_REQUESTS) {
    await new Promise(resolve => {
      const detach = pendingRequests.observe(({ newValue }) => {
        if (index >= newValue) {
          detach();
          resolve();
        }
      });
    });
  }
  inFlightRequests += 1;

  /**
   * @see https://github.com/axios/axios/issues/978
   */
  return Object.assign(config, { cancelToken: source.token });
});

api.interceptors.response.use(
  async res => {
    inFlightRequests = Math.max(0, inFlightRequests - 1);
    pendingRequests.set(Math.max(0, pendingRequests.get() - 1));
    return res;
  },
  async err => {
    inFlightRequests = Math.max(0, inFlightRequests - 1);
    pendingRequests.set(Math.max(0, pendingRequests.get() - 1));
    throw err;
  }
);

export default api;
