import React, { Fragment } from 'react';

import { AssessmentListing, KillChain } from '../../models/Assessment';
import { doHashCompareAssessment } from '../helpers';
import media from '../../assets/MalwareDeliveryWithInvalidTLSCertificate.jpg';

const assessment: AssessmentListing = {
  category: KillChain.ACCESS,
  name: 'Malware Delivery with Invalid TLS Certificate',
  media,
  longDesc: (
    <Fragment>
      <p>
        This test downloads an inert version of known Shamoon malware from a website that is
        intentionally configured with an invalid TLS certificate.
      </p>
      <ul>
        <li>
          IronNet does not execute any malicious programs or send any actual malicious
          communications or private data.
        </li>
      </ul>
    </Fragment>
  ),
  shortDesc:
    'This test simulates the attempted delivery of malware using a website with an invalid TLS certificate.',
  characteristics:
    'A normal TLS connection is created to a website with an invalid server certificate. This connection may succeed or fail, depending on the enterprise security configuration. If it succeeds, this connection is then used to transfer a known malware executable over HTTPS.',
  warnings: {
    ironnet: [
      'Download an inert version of known malware from a website using the TLS protocol with an invalid server certificate.'
    ],
    attacker: [
      'Potentially trick users into downloading malware.',
      'Users may run downloaded malware, giving the attacker access to company computers, servers, and other infrastructure.',
      'Users will often click past warnings about invalid certificates.',
      'Common malware often uses invalid TLS certificates to establish communications.'
    ]
  },
  why:
    'Various types of malware often use the HTTPS protocol but fail to obtain valid TLS certificates. Despite the conspicuous nature of these communications, this less sophisticated method is often still successful for delivering malware in some environments.',
  how:
    'IronDefense identifies communications where a destination server’s SSL/TLS certificate could not be validated against a root certificate authority. The analytic is used to quickly identify self-signed or otherwise fraudulent certificates.',
  doAssessment: () => doHashCompareAssessment(`${process.env.REACT_APP_ACCESS_05}/ntssrvr64.exe`)
};

export default assessment;
