import cc from 'classcat';
import React, { FC, HTMLProps, ReactElement } from 'react';

import styles from './Container.module.css';

interface ContainerProps extends HTMLProps<HTMLDivElement> {}

const Container: FC<ContainerProps> = ({ children, className, ...rest }): ReactElement => (
  <div className={cc([styles.container, className])} {...rest}>
    {children}
  </div>
);

export default Container;
