import cc from 'classcat';
import React, { FC, HTMLProps, ReactElement } from 'react';

import styles from './Heading.module.css';

interface HeadingProps extends HTMLProps<HTMLHeadingElement> {
  tag: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
}

const Heading: FC<HeadingProps> = ({ tag: Tag, className, children, ...rest }): ReactElement => (
  <Tag className={cc([styles[Tag], className])} {...rest}>
    {children}
  </Tag>
);

export default Heading;
