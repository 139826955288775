import React, { FC, useState, useContext, useLayoutEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import AssessmentsStore from '../../stores/AssessmentsStore';
import Heading from '../Heading';
import Modal from '../Modal';

const CompletionModal: FC = observer(() => {
  const { percentComplete } = useContext(AssessmentsStore);

  const [showModal, setShowModal] = useState(false);

  useLayoutEffect(() => {
    if (percentComplete === 100) {
      setShowModal(true);
    }
  }, [percentComplete]);

  return (
    <TransitionGroup>
      {showModal ? (
        <CSSTransition in={showModal} timeout={300} classNames="fade">
          <Modal onClose={() => setShowModal(false)} useOkCloseButton width="small">
            <Heading tag="h2">Your Cyber Assessment Report is Ready!</Heading>
            <p>Thank you for your patience.</p>
            <p>
              A reminder that these tests have been carefully designed to not expose your network to
              risk. While these tests are benign, they may trip alarms within your internal security
              systems.
            </p>
            <p>
              Should you have any questions, our team of experts can review your Cyber Assessment
              Report with you. We can provide you with actions you can take to improve your security
              posture.
            </p>
          </Modal>
        </CSSTransition>
      ) : null}
    </TransitionGroup>
  );
});

export default CompletionModal;
