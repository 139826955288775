import React, { FC, ReactElement } from 'react';

import Button from '../Button';
import styles from './Start.module.css';

interface StartProps {
  runTests: () => void;
}

const Start: FC<StartProps> = ({ runTests }): ReactElement => (
  <div className={styles.default}>
    <div>
      <div className={styles.disclaimer}>
        <p>
          The{' '}
          <strong>
            <em>Cyber Assessment Tool</em>
          </strong>{' '}
          loads in your browser just like a normal webpage and performs a series of simulations that
          are designed to closely emulate malicious actions. The simulations cover an array of
          common malicious activity intended to steal confidential information, deliver malware to
          unwitting users, and maintain control of compromised systems. As the simulations are
          performed, our system verifies the behavior was successful and presents the results in
          real time.
        </p>
        <h3>To be clear:</h3>
        <ul>
          <li>
            These tests are benign, use secure communication protocols, and do not pose any risk to
            your enterprise.
          </li>
          <li>
            The results of these tests are completely confidential &ndash; IronNet Cybersecurity
            Inc. will not share nor do we record any personal or proprietary information about the
            user of the Cyber Assessment Tool, the computer or network used by the Cyber Assessment
            Tool.
          </li>
        </ul>
        <p>
          As an extension of our corporate website, the{' '}
          <strong>
            <em>Cyber Assessment Tool</em>
          </strong>{' '}
          may implicity collect certain information about your device and usage of the site through
          analytic technologies and log files. Please refer to our{' '}
          <a
            href="https://ironnet.com/privacy-statement/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Statement
          </a>{' '}
          (specifically the “What Personal Data do we collect?” section) for additional information.
        </p>
      </div>
      <div className={styles.ctas}>
        <p>
          <Button className="primary" onClick={runTests}>
            Assess Your Risk
          </Button>
        </p>
        <p>
          <a href="https://ironnet.com">Return to IronNet.com</a>
        </p>
      </div>
    </div>
  </div>
);

export default Start;
