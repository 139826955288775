import React, { Fragment } from 'react';

import { AssessmentListing, KillChain } from '../../models/Assessment';
import { doDataTheftAssessment } from '../helpers';
import media from '../../assets/BulkProprietaryDataTheft.jpg';

const assessment: AssessmentListing = {
  category: KillChain.ACTION,
  name: 'Bulk Proprietary Data Theft',
  media,
  priority: Infinity, // this test is slow, start it first
  longDesc: (
    <Fragment>
      <p>
        This test simulates the theft of a large quantity of proprietary data, such as software
        source code.
      </p>
      <ul>
        <li>IronNet does not collect or send any proprietary data during this test.</li>
      </ul>
    </Fragment>
  ),
  shortDesc:
    'This test simulates the theft of a large quantity of proprietary data, such as software source code.',
  characteristics: 'A large volume of data is sent from the browser to an adversary’s web server.',
  warnings: {
    ironnet: [
      'Transmit a large volume of simulated sensitive Intellectual Property to our web server.'
    ],
    attacker: [
      'Steal large amounts of financial data, medical data, trade secrets, sensitive business details, and other closely-guarded information.',
      'Use stolen data to further target internal systems, people, or other companies.'
    ]
  },
  why:
    'Attackers often succeed in stealing large caches of proprietary data using normal web protocols, such as HTTPS.   Identifying data loss can be challenging, especially if large volumes of data are egressed slowly over minutes or hours.  Very few cybersecurity tools can identify this behavior.',
  how:
    'IronDefense analytics can detect when a computer is sending out more traffic than established baseline behavior patterns. This activity may indicate a data breach by an active intruder, malware, or an insider threat. This analytic continuously updates models to accurately determine anomalously high percentiles for every computer asset on your network.',
  doAssessment: () => doDataTheftAssessment(`${process.env.REACT_APP_ACTON_01}/upload/<count>`)
};

export default assessment;
