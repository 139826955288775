import cc from 'classcat';
import { observer } from 'mobx-react-lite';
import React, { FC, useContext, useState, useRef, useEffect, useLayoutEffect } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import { ReactComponent as ArrowRight } from '../../assets/Icon/ArrowRight.svg';
import { ReactComponent as Email } from '../../assets/Icon/Email.svg';
import { ReactComponent as Phone } from '../../assets/Icon/Phone.svg';
import { ReactComponent as Help } from '../../assets/Icon/Help.svg';
import { ReactComponent as Download } from '../../assets/Icon/Download.svg';
import AssessmentsStore from '../../stores/AssessmentsStore';
import layout from '../../styles/layout.module.css';
import { CONTACT_URL } from '../../urls';
import Button from '../Button';
import Container from '../Container';
import Footer from '../Footer';
import Heading from '../Heading';
import Modal from '../Modal';
import TestPanels from '../TestPanels';
import ResultPanels from '../ResultPanels';
import Loading from '../Loading';
import styles from './AssessmentResults.module.css';
import gaugeDefault from '../../assets/gauge_default.svg';
import gaugeExcellent from '../../assets/gauge_excellent.svg';
import gaugeGood from '../../assets/gauge_good.svg';
import gaugeAverage from '../../assets/gauge_average.svg';
import gaugePoor from '../../assets/gauge_poor.svg';
import Report from '../../models/Report';
import DownloadReportForm from '../DownloadReportForm';

interface AssessmentResultsProps {}

const AssessmentResults: FC<AssessmentResultsProps> = observer(() => {
  const {
    testsFinishedAt,
    summaryTotals,
    cancelTests,
    percentComplete,
    assessments,
    testsStartedAt
  } = useContext(AssessmentsStore);
  const pdfReportRef = useRef<HTMLDivElement>(null);
  const resultsRef = useRef<HTMLDivElement>(null);
  const [showContactModal, setShowContactModal] = useState(false);
  const [showHelpModal, setShowHelpModal] = useState(false);
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [isFormSubmitted, setFormSubmitted] = useState(false);

  /* Cleanup routine to cancel all tests if the user leaves the page */
  useEffect(() => {
    // eslint-disable-next-line no-restricted-globals
    window.addEventListener('beforeunload', event => {
      event.preventDefault();
      // eslint-disable-next-line no-param-reassign
      event.returnValue = '';
      cancelTests();
    });

    return () => {
      // eslint-disable-next-line no-restricted-globals
      window.removeEventListener('beforeunload', () => cancelTests());
    };
  }, [cancelTests]);

  /**
   * In the event that the tests complete while the Help Modal is open this allows us to handle the
   * Modal collision gracefully by closing the Help Modal at the same time that we open the
   * CompletionModal. The Modal from the UI library will handle this more gracefully (Modals will
   * stack with the latest to open on top) but it isn't ready yet.
   *
   * TODO: Remove this once we are using the UI library's Modal.
   */
  useLayoutEffect(() => {
    if (percentComplete === 100) {
      setShowHelpModal(false);
    }
  }, [percentComplete]);

  async function downloadReport(): Promise<void> {
    const report = new Report(
      assessments,
      summaryTotals,
      testsStartedAt || '',
      testsFinishedAt || ''
    );
    report.generate();
  }

  function completeSubmission(): void {
    setFormSubmitted(true);
    downloadReport();
  }

  function handleShowContactModal(): void {
    setShowContactModal(true);
    if (isFormSubmitted) {
      downloadReport();
    }
  }

  function gaugeImg(): string {
    const score: number = summaryTotals.highRisk / summaryTotals.total;
    if (score < 0.25) {
      return gaugeExcellent;
    }
    if (score < 0.5) {
      return gaugeGood;
    }
    if (score < 0.75) {
      return gaugeAverage;
    }
    return gaugePoor;
  }

  return (
    <div>
      <Container className={cc([layout.grid, styles.default])}>
        <aside className={styles.sidebarContainer}>
          <TransitionGroup className={styles.sidebar}>
            <CSSTransition in={!!testsFinishedAt} timeout={300} classNames="fade">
              <div className={styles['killchain-section']}>
                <div className={!testsFinishedAt ? styles.isLoading : ''}>
                  <figure className={styles.gauge}>
                    <img src={!testsFinishedAt ? gaugeDefault : gaugeImg()} alt="Gauge" />
                  </figure>
                </div>
                {!testsFinishedAt && (
                  <div className={styles.loadingOverlay}>
                    <Loading />
                  </div>
                )}
              </div>
            </CSSTransition>
            <CSSTransition in={!!testsFinishedAt} timeout={300} classNames="fade">
              <div className={styles['about-section']}>
                <Heading tag="h2">Get Expert Analysis</Heading>
                <p className={styles.about}>
                  Our team of experts can review your Cyber Assessment Report with you. We can
                  provide you with actions you can take to improve your security posture.
                </p>

                <p className={styles['contact-button']}>
                  <Button
                    isLink
                    href={CONTACT_URL}
                    className="primary"
                    icon={<ArrowRight />}
                    iconPlacement="right"
                  >
                    Contact IronNet
                  </Button>
                  <Button
                    disabled={!testsFinishedAt}
                    className="primary"
                    icon={<Download />}
                    iconPlacement="right"
                    onClick={() => handleShowContactModal()}
                  >
                    Download
                  </Button>
                </p>
                <div className={styles['contact-info']}>
                  <dl className={styles['contact-list']}>
                    <dt>
                      <Phone />
                    </dt>
                    <dd>
                      <span className={styles['contact-label']}>Phone</span>
                      <br />
                      (443) 300.6761
                    </dd>
                    <dt>
                      <Email />
                    </dt>
                    <dd>
                      <span className={styles['contact-label']}>Email</span>
                      <br />
                      info@ironnet.com
                    </dd>
                  </dl>
                </div>
              </div>
            </CSSTransition>
          </TransitionGroup>
        </aside>

        <aside className={styles.sidebarContainer}>
          <Heading tag="h2">Assessment Results By Kill Chain</Heading>
          <TransitionGroup className={styles.resultsSidebar}>
            <CSSTransition in={!!testsFinishedAt} timeout={300} classNames="fade">
              <div>
                <div className={!testsFinishedAt ? styles.isLoading : ''}>
                  <ResultPanels />
                </div>
                {!testsFinishedAt && (
                  <div className={styles.resultsLoadingOverlay}>
                    <Loading />
                  </div>
                )}
              </div>
            </CSSTransition>
          </TransitionGroup>
        </aside>

        <main className={styles.main} ref={resultsRef} id="results-ref">
          <Heading tag="h2" id="tests-performed">
            Tests Performed
          </Heading>
          <TestPanels />
        </main>
      </Container>
      <Footer />
      <div className={styles.fab}>
        <Button className="fab" onClick={() => setShowHelpModal(true)}>
          <Help />
        </Button>
      </div>
      <TransitionGroup>
        {showContactModal && (
          <CSSTransition in={showContactModal} timeout={300} classNames="fade">
            <Modal onClose={() => setShowContactModal(false)}>
              <DownloadReportForm
                completeSubmission={completeSubmission}
                isFormSubmitted={isFormSubmitted}
                summaryTotals={JSON.stringify(summaryTotals)}
              />
            </Modal>
          </CSSTransition>
        )}
        {showHelpModal && (
          <CSSTransition in={showHelpModal} timeout={300} classNames="fade">
            <Modal onClose={() => setShowHelpModal(false)}>
              <Heading tag="h2">About IronNet&apos;s Cyber Assessment Tool</Heading>
              <p className={styles.spaced}>
                These tests have been carefully designed to not expose your network to risk. While
                these tests are benign, they may trip alarms within your internal security systems.
                Each test performs some simulation of communications used by known malware or
                unknown attackers. We then verify that the communications were successful, in order
                to determine the possible risk severity even with your existing security systems.
              </p>
            </Modal>
          </CSSTransition>
        )}
        {showDownloadModal && (
          <CSSTransition in={showDownloadModal} timeout={300} classNames="fade">
            <Modal onClose={() => setShowDownloadModal(false)}>
              <div ref={pdfReportRef} style={{ height: 2000, width: 2000, overflow: 'scroll' }} />
            </Modal>
          </CSSTransition>
        )}
      </TransitionGroup>
    </div>
  );
});

export default AssessmentResults;
