import React, { FC, HTMLProps, ReactElement, Fragment, FormEvent, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import axios, { AxiosResponse } from 'axios';

import styles from './DownloadReportForm.module.css';
import { TERMS_URL, CONTACT_URL } from '../../urls';
import Heading from '../Heading';
import Button from '../Button';
import { ReactComponent as Download } from '../../assets/Icon/Download.svg';
import { ReactComponent as ArrowRight } from '../../assets/Icon/ArrowRight.svg';

interface DownloadReportFormProps extends HTMLProps<HTMLDivElement> {
  completeSubmission: () => void;
  isFormSubmitted: boolean;
  summaryTotals: string;
}

const DownloadReportForm: FC<DownloadReportFormProps> = ({
  completeSubmission,
  isFormSubmitted,
  summaryTotals
}): ReactElement => {
  const formRef = useRef<HTMLFormElement>(null);
  const captchaRef = useRef<ReCAPTCHA>(null);
  const [email, setEmail] = useState('');
  const [consent, setConsent] = useState(false);
  const [showCaptchaError, setShowCaptchaError] = useState(false);
  const [captchaConfirmation, setCaptchaConfirmation] = useState<string | null>(null);
  const [showSubmitError, setShowSubmitError] = useState(false);

  function sendContactInfo(): Promise<AxiosResponse> {
    const params = new URLSearchParams();
    params.append('email', email);
    params.append('consent', consent ? 'true' : 'false');
    params.append('captcha', captchaConfirmation || '');
    params.append('assessmentScore__c', summaryTotals);
    const options = {
      method: 'POST',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      data: params,
      url: `${process.env.REACT_APP_API_BASE_URL}/contact`
    };
    return axios(options);
  }

  function resetErrors(): void {
    setShowCaptchaError(false);
    setShowSubmitError(false);
  }

  function handleSubmit(e: FormEvent): void {
    e.preventDefault();
    resetErrors();

    if (!captchaConfirmation) {
      setShowCaptchaError(true);
      return;
    }

    sendContactInfo()
      .then(() => {
        completeSubmission();
      })
      .catch(err => {
        console.error(err);
        setShowSubmitError(true);
      });
  }

  const emailForm = (
    <Fragment>
      <Heading tag="h2">Download Your Free Cyber Assessment Report</Heading>
      <p className={styles.modalText}>
        We hope this preview has been helpful! In order to download the report, please enter your
        email address.
      </p>
      <form onSubmit={handleSubmit} ref={formRef}>
        <div className={styles.formField}>
          <label htmlFor="emailAddress" className={styles.formLabel}>
            Email
          </label>
          <input
            type="email"
            id="emailAddress"
            name="emailAddress"
            className={styles.formInput}
            placeholder="e.g. email@example.com"
            value={email}
            onChange={e => setEmail(e.target.value)}
            required
          />
        </div>

        <div className={styles.formTerms}>
          <Heading tag="h4">
            IronNet will use the information you provide on this form to contact you.
          </Heading>

          <div>
            <label>
              <input
                type="checkbox"
                required
                name="consent"
                onChange={e => setConsent(e.target.checked)}
              />{' '}
              I consent to allow IronNet to collect details through this form to contact me.
            </label>
          </div>
          <p>
            By submitting this form, you are confirming that you have read and agree to our{' '}
            <a href={TERMS_URL} target="_blank" rel="noopener noreferrer">
              Terms and Privacy Statement
            </a>
            .
          </p>
        </div>

        <div>
          <ReCAPTCHA
            ref={captchaRef}
            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || ''}
            onChange={() => {
              if (captchaRef && captchaRef.current) {
                const val = captchaRef && captchaRef.current ? captchaRef.current.getValue() : '';
                setCaptchaConfirmation(val);
                setShowCaptchaError(false);
              }
            }}
          />
          {showCaptchaError && <p className={styles.formError}>This field is required.</p>}
        </div>

        <div className={styles.formActions}>
          {showSubmitError && (
            <p className={styles.formError}>
              We were unable to complete the download request. If you wish to save your assessment,
              please use your browser&apos;s print function.
            </p>
          )}
          <Button className="primary" type="submit" icon={<Download />} iconPlacement="right">
            Download Report
          </Button>
        </div>
      </form>
    </Fragment>
  );

  const emailFormSubmitted = (
    <div className={styles.formSubmitted}>
      <Heading tag="h2">Your Free Cyber Assessment Report Is Downloading!</Heading>
      <Heading tag="h3">What now?</Heading>
      <p>
        Let our team of experts review your Cyber Assessment Report with you. We can provide you
        with actions you can take to improve your security posture.
      </p>
      <p>
        <Button
          isLink
          href={CONTACT_URL}
          className="primary"
          icon={<ArrowRight />}
          iconPlacement="right"
        >
          Contact Us
        </Button>
      </p>
    </div>
  );

  return isFormSubmitted ? emailFormSubmitted : emailForm;
};

export default DownloadReportForm;
