import React, { Fragment } from 'react';

import { AssessmentListing, KillChain } from '../../models/Assessment';
import { doRendezvousAssessment } from '../helpers';
import media from '../../assets/UnknownMalwareRendezvous.jpg';
import api from '../../api';

const assessment: AssessmentListing = {
  category: KillChain.C2,
  name: 'Unknown (New) Malware Rendezvous',
  media,
  longDesc: (
    <Fragment>
      <p>
        This test uses a custom DGA with no existing threat intel signatures to rendezvous with a C2
        server.
      </p>
      <ul>
        <li>This is a safe simulation. IronNet does not send data to these domains.</li>
      </ul>
    </Fragment>
  ),
  shortDesc:
    'This test simulates a custom, non-signatured domain generation algorithm (DGA) called Lowky.',
  characteristics:
    'DNS requests are sent for new domain names generated by a custom algorithm. The final DNS request resolves to an IP address, followed by an HTTPS connection to the resolved domain.',
  warnings: {
    ironnet: ['Request DGA domains using a custom algorithm and rendezvous with a C2 server.'],
    attacker: [
      'Use similar requests to evade defenses which rely on blacklists of known C2 domains.',
      'Cause security teams to spend unnecessary resources ensuring malicious domain lists are up to date.',
      'Use similar algorithms to quickly recover from server takedowns and other actions meant to disrupt malicious operations.'
    ]
  },
  why:
    'Attackers continue to evolve their tradecraft making detection of malware increasingly difficult.  New malware strains are extremely effective at evading detection by traditional signature-based security systems.',
  how:
    'IronDefense DGA analytics use an in intricate set of domain features to classify DGA activity, so even malware communications with no known signatures can be identified.',
  doAssessment: async () => {
    const response = await api.get(`${process.env.REACT_APP_API_BASE_URL}/umrdomains.json`);
    const { domains } = response.data;
    return doRendezvousAssessment(domains, `${process.env.REACT_APP_C2_04}/s`);
  }
};

export default assessment;
