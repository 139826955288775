import cc from 'classcat';
import React, { FC, HTMLProps, ReactElement } from 'react';

import Icon from '../Icon';
import styles from './IconList.module.css';

interface IconListProps extends HTMLProps<HTMLUListElement> {
  icon: 'warning' | 'check' | 'high' | 'low' | null;
  items: string[];
  mode?: 'small';
}

const IconList: FC<IconListProps> = ({ items, icon, mode }): ReactElement => (
  <ul className={cc([styles.default, mode && styles[mode]])}>
    {items.map(item => (
      <li key={item} className={styles.item}>
        {icon && (
          <span className={styles.icon}>
            <Icon type={icon} mode={mode} bubble wrap="wrap" />
          </span>
        )}
        <span>{item}</span>
      </li>
    ))}
  </ul>
);

export default IconList;
